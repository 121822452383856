// For Firebase JavaScript SDK v7.20.0 and later, `measurementId` is an optional field
import firebase from "firebase/app";

import "firebase/auth";

export const firebaseConfig = {
    apiKey: "AIzaSyBWDiuQtctRLOKs_DNQpfoiS6KOkzgFllg",
    authDomain: "multinations-project.firebaseapp.com",
    projectId: "multinations-project",
    storageBucket: "multinations-project.appspot.com",
    messagingSenderId: "729076584488",
    appId: "1:729076584488:web:0345c408fe73e95546651d",
    measurementId: "G-SGBL49J8FV"
};

firebase.initializeApp(firebaseConfig);

export default firebase;



