import jwtDecode from "jwt-decode";

const getToken = () => {
    return localStorage.getItem("token");

}

export const getUserUid = () =>{
    let token = getToken();
    if (token == null)
        return "null"
    const decoded = jwtDecode(token);
    return decoded.user_id;
}

export const isUserLoggedIn = () => {
    let token = getToken();
    return !(token == null || !token);
    // return true 

}

export default getToken;


