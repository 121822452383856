import React, {useEffect, useState} from 'react'
import {Redirect} from 'react-router-dom'
import '../../../firebase'
import "firebase/auth";
// import firebase from "firebase/auth";
import firebase from "../../../firebase";
import jwtDecode from "jwt-decode";
import '../../../services/auth/auth.service'
import getToken, {isUserLoggedIn} from "../../../services/auth/auth.service";
import {selfUrl} from "../../../base_urls";
// import firebase from "firebase";

var Spinner = require('react-spinkit');


var actionCodeSettings = {
  // URL you want to redirect back to. The domain (www.example.com) for this
  // URL must be in the authorized domains list in the Firebase Console.
  url: 'http://localhost:3000/#/login',
  // This must be true.
  handleCodeInApp: true,
 /* iOS: {
    bundleId: 'com.example.ios'
  },
  android: {
    packageName: 'com.example.android',
    installApp: true,
    minimumVersion: '12'
  },
  dynamicLinkDomain: 'localhost:3000'*/
};

const VerifyAuth = () => {
  const [isLoggedin, setIsLoggedIn] = useState(false);

  useEffect(()=> {

  }, [isLoggedin])


  const verifyAuthentication = () => {
    // console.log("I am doing ")
    // Confirm the link is a sign-in with email link.
    if (firebase.auth().isSignInWithEmailLink(window.location.href)) {
      // Additional state parameters can also be passed via URL.
      // This can be used to continue the user's intended action before triggering
      // the sign-in operation.
      // Get the email if available. This should be available if the user completes
      // the flow on the same device where they started it.
      var email = localStorage.getItem('emailForSignIn');
      if (!email) {
        // console.log("No email found");
        // User opened the link on a different device. To prevent session fixation
        // attacks, ask the user to provide the associated email again. For example:
        window.location.replace(selfUrl+"/#/login");
        // email = window.prompt('Please provide your email for confirmation');
      }
      // The client SDK will parse the code from the link for you.
      firebase.auth().signInWithEmailLink(email, window.location.href)
          .then((result) => {
            // console.log("Email ", email);
            // Clear email from storage.
            localStorage.removeItem('emailForSignIn');
            // You can access the new user via result.user
            // Additional user info profile not available via:
            // result.additionalUserInfo.profile == null
            // You can check if the user is new or existing:
            // result.additionalUserInfo.isNewUser
            console.log("User: ", result.user.getIdToken().then(token => {
              const decoded = jwtDecode(token);
              console.log(decoded);
              localStorage.setItem('token', token);
              localStorage.setItem('user_email', decoded.email);
              localStorage.setItem('expires', decoded.exp);
              setIsLoggedIn(true);
              window.location.replace(selfUrl+'/#/multinations');
            }));
            // <Redirect to={} />
          })
          .catch((error) => {
            // Some error occurred, you can inspect the code: error.code
            // Common errors could be invalid email and invalid or expired OTPs.
            console.log(error);
          });
    }
  }

  return (
    <div className="c-app c-default-layout flex-row align-items-center" style={{backgroundColor:`#fff` }}>
    {/* <div className="c-app c-default-layout flex-row align-items-center" style={{backgroundImage: `url('https://agroshub.eu/wp-content/uploads/2020/06/homePozadina.jpg')`}}> */}
      <Spinner name="folding-cube" color="orange"/>
      {(
          () => {
            if (isUserLoggedIn() || isLoggedin){
              console.log("Verify Token: ", getToken())
              // window.location.replace(selfUrl+"/#/agros");
              return(
                  <Redirect push to='/multinations' />
              )

            }
            verifyAuthentication();
          }
      )()}
    </div>
  )
}

export default VerifyAuth;
